import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import SocialMedia from "../components/SocialMedia"
import OtherContents from "../components/OtherContents"
import './style.css'

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const prev = post.frontmatter.prev;
  const next = post.frontmatter.next;

  return (
    <Layout location={location}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        og_description={post.frontmatter.og_description || post.description}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <section
          className="post-content"
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />

        <nav className="post-navigation">
          {prev &&
            <a className="prev subheading-green" href={prev}>Recomendação anterior</a>
          }

          {next &&
            <a className="next subheading-green" href={next}>Próxima recomendação</a>
          }
        </nav>
      </article>
      <OtherContents />
      <SocialMedia />
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        prev
        next
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
